<template>
    <div class="content">
      <div class="search">
        <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="车辆入场记录" :fetch="createExportData" :before-finish="finishDownload" worksheet="车辆入场记录">
            <el-button type="primary" class="el-icon-download"> 导出</el-button>
        </JsonExcel>
      </div>

        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>车牌号：</label>
                <el-input v-model="searchForm.plateno" class="input-with-select" style="width:200px"></el-input>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="search">
                <label style="width: 150px;">出场时间：</label>
                 <el-date-picker v-model="searchForm.intime" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>公司：</label>
                <el-select v-model="searchForm.companyId" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for='item in $store.state.CompanyDataList' :key='item.cp_id' :label='item.cp_name' :value='item.cp_id'></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>工地：</label>
                <el-select v-model="searchForm.buildingId" style="width:150px" >
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in buildingDataList" :label="item.lt_name" :value="item.lt_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <label>消纳点：</label>
                <el-select v-model="searchForm.locationId" style="width:150px" >
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in locationDataList" :label="item.lt_name" :value="item.lt_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="2">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

         <el-table ref="enterTable" :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini" >
            <el-table-column align="center" prop="log_plateno" label="车牌号"></el-table-column>
            <el-table-column align="center" prop="car_name" label="姓名"></el-table-column>
            <el-table-column align="center" prop="log_exit_time" label="出场时间">
              <template slot-scope="scope">
                {{FormData(scope.row.log_exit_time)}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="lt_name" label="工地/消纳点"></el-table-column>
            <el-table-column align="center" prop="cp_name" label="公司"></el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>
    </div>
</template>

<script>
import {post,get,FormateDate} from '../../util/util'
import JsonExcel from 'vue-json-excel'

export default {
  data: function () {
    return {
      clientWidth:0,
      searchForm: {
        plateno: '',
        intime: [],
        companyId:0,
        locationId:0,//消纳点id
        buildingId:0,//工地id
        pageSize: 15,
        pageNo: 1,
        total: 0
      },
      buildingDataList:[],
      locationDataList:[],
      companyDataList:[],

      DataList: [],

       // 导出
      exportDataStandard: {
       
      },
      exportData: [],
    }
  },
  computed: {
    FormData () {
      return function (time) {
        if(time)
          return FormateDate(time, 2)
      }
    },
  },
  components: {
    JsonExcel
  },
  created () {
    this.clientWidth= document.documentElement.clientWidth
    this.GetDataList()
    get("location/getlocationdatalist").then(res=>{
      if(res.rpStatus===10000){
        res.list.forEach(item=>{
          if(item.lt_type===1){
            this.locationDataList.push(item)
          }
          else
            this.buildingDataList.push(item)
        })
      }
    })
    if(this.$store.state.CompanyDataList.length===0){
        this.$store.dispatch('getCompanyDataList')
    }
  },
  methods: {
    GetDataList () {
      get('logexit/getDataList', this.searchForm).then(res => {
        if (res.rpStatus === 10000) {
          this.DataList = res.list
          this.searchForm.total = res.total
        }
      })
    },
    Search () {
        this.GetDataList()
    },
    handleSizeChange(pageSize){
      this.searchForm.pageSize=pageSize
      this.GetDataList()
    },
    current_change (page) {
      this.searchForm.pageNo = page
      this.GetDataList()
    },

    // 导出
    async createExportData () {
        this.searchForm.pageNo = 0
        var form = JSON.parse(JSON.stringify(this.searchForm))
        form.pageNo = 0
        var result = await get('logexit/getDataList', form)
        if (result.rpStatus === 10000) {
          this.exportData = result.list
          return this.exportData
        }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
.a{
  font-size: 13px;
  margin-bottom: 5px;
  font-family:sans-serif
}
</style>
